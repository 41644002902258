import { AddIcon } from '@chakra-ui/icons';
import { fetchAdmins, fetchAdminsWithSearch, inviteAdmin } from '../../../data/request';
import React, { useContext, useState } from 'react'
import { AnalyticaTable } from '../../table';
import { AnalyticaForm } from '../../form';
import { validateEmail } from '../../../data/validations';
import { useDisclosure } from '@chakra-ui/react';
import { ICON_ADMIN } from '../../../theme';
import { AccountContext } from '../../Account';
import { AnalyticaModal } from '../../modal';
import { TextInput } from '../../form/input';
import { AddButton } from '../../button';
import { fullname } from '../../../data/functions';

export const Admins = () => {

  const [render, setRender] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { setPageTitle } = useContext(AccountContext)
  setPageTitle("Admins")

  const closeAndRender = (close: () => void) => {
    close()
    setRender(!render)
  }

  let columns = [
    { name: "Name", component: fullname },
    { "id": "email", "name": "Email Address" }
  ]

  return (
    <>
      {/* table of orgs */}
      <AnalyticaTable
        columns={columns}
        icon={ICON_ADMIN}
        fetchItems={fetchAdmins}
        fetchItemsWithSearch={fetchAdminsWithSearch}
        searchable
        searchOnChange
      >
        <AddButton
          title='Invite Admin'
          onClick={onOpen}
          testId="invite-admins-button"
          rightIcon={<AddIcon />}
        />

      </AnalyticaTable>

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => closeAndRender(onClose)}
      >
        <AnalyticaForm
          initialValues={{}}
          onSubmit={inviteAdmin}
          onClose={() => closeAndRender(onClose)}
          name='invite-admin'
          title='Invite an Admin'
          description='Invite a new Admin to Rubric Analytica.'
        >
          <TextInput
            name="email"
            title="Email Address"
            testId='admin-email-input'
            validation={validateEmail}
          />
        </AnalyticaForm>
      </AnalyticaModal>

    </>
  )
}
