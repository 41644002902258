import React from 'react'
import { PasswordInput, PasswordInputProps } from './PasswordInput'
import { validateConfirmPassword } from '../../../data/validations'
import { useFormikContext } from 'formik'

export const ConfirmPasswordInput = (props: PasswordInputProps) => {

  const { values } = useFormikContext<{ password: string }>()

  return <PasswordInput
    name={props.name || "confirmPassword"}
    title={props.title || "Confirm Password"}
    validation={props.validation || validateConfirmPassword(values.password)}
    {...props}
  />
}

