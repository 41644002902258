// thought this could possibly be undefined too
type idstr = string

export class ID {
  id: idstr;

  constructor(id: idstr) {
    this.id = id
  }
}

export class Person extends ID {
  preferredName: string;
  lastName: string;

  constructor(id: idstr, preferredName: string, lastName: string) {
    super(id)
    this.preferredName = preferredName
    this.lastName = lastName
  }
}

export class Named extends ID {
  name: string;

  constructor(id: idstr, name: string) {
    super(id)
    this.name = name
  }
}

export class User extends Person {
  cognitoId: string;

  constructor(id: idstr, preferredName: string, lastName: string, cognitoId: string) {
    super(id, preferredName, lastName)
    this.cognitoId = cognitoId
  }
}

export class Admin extends User { }

export class StaffMember extends User {
  orgId: string;

  constructor(id: idstr, preferredName: string, lastName: string, cognitoId: string, orgId: string) {
    super(id, preferredName, lastName, cognitoId)
    this.orgId = orgId
  }
}

export class Student extends Person {
  orgId: string;
  classId: string;
  identifier: string;
  yearLevel: number;
  level: number;

  constructor(id: idstr, preferredName: string, lastName: string, orgId: string, classId: string, identifier: string, yearLevel: number, level: number) {
    super(id, preferredName, lastName)
    this.orgId = orgId
    this.classId = classId
    this.identifier = identifier
    this.yearLevel = yearLevel
    this.level = level
  }
}

export class Org extends Named {
  primaryAddress: string;

  constructor(id: string, name: string, primaryAddress: string) {
    super(id, name)
    this.primaryAddress = primaryAddress
  }
}

export class Class extends Named {
  orgId: string;
  avgScore: number;
  avgTimeRemaining: number;
  numStudents: number;
  numTestsCompleted: number;

  constructor(id: idstr, name: string, orgId: string, avgScore: number, avgTimeRemaining: number, numStudents: number, numTestsCompleted: number) {
    super(id, name)
    this.orgId = orgId
    this.avgScore = avgScore
    this.avgTimeRemaining = avgTimeRemaining
    this.numStudents = numStudents
    this.numTestsCompleted = numTestsCompleted
  }
}

export class Test extends ID {
  classId: string;
  created: string;
  completed: boolean;

  constructor(id: idstr, classId: string, created: string, completed: boolean) {
    super(id)
    this.classId = classId
    this.created = created
    this.completed = completed
  }
}

export class Result extends ID {
  testId: string;
  studentId: string;
  classId: string;
  orgId: string;
  score: number;
  timeRemaining: number;
  completed: boolean;

  constructor(id: idstr, testId: string, studentId: string, classId: string, orgId: string, score: number, timeRemaining: number, completed: boolean) {
    super(id)
    this.testId = testId
    this.studentId = studentId
    this.classId = classId
    this.orgId = orgId
    this.score = score
    this.timeRemaining = timeRemaining
    this.completed = completed
  }
}

export class StudentAndResult {
  student: Student;
  result: Result;

  constructor(student: Student, result: Result) {
    this.student = student
    this.result = result
  }
}
