import { Box, Text, Button, Input, Spacer, Table, Tbody, Td, Th, Thead, Tr, IconButton, Modal, ModalOverlay, ModalCloseButton, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";
import { Formik, Form, FieldArray } from "formik";
import { useContext } from "react";
import { BiX } from "react-icons/bi";
import React from 'react'
import { AccountContext } from "../../../Account";
import { downloadImportTemplateCSV } from "../../../../data/request";
import { CSVUpload } from "../../../upload/csv";
import { Student } from "../../../../data/classes";
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK } from "../../../../theme";
import { CancelButton, SaveButton } from "../../../button";

interface Props {
  title: string
  description: string
  initialValues: Student
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onSubmit: (...args: any[]) => void
  students: Student[]
  setStudents: Function
}

export const ImportStudents = (props: Props) => {

  const { token } = useContext(AccountContext)

  const download = () => {
    if (!token) {
      return
    }
    downloadImportTemplateCSV(token, "template.csv")
  }

  let importStudentsFromCSV =
    <ModalContent borderRadius={10} border={BORDER_DEFAULT}>

      <ModalHeader>{props.title}</ModalHeader>

      <ModalCloseButton />

      <ModalBody>
        <Text>{props.description}</Text>
        <Box pt={10} />
        <Button variant='link' textColor={COLOUR_PRIMARY_DARK} onClick={download} >Download CSV Template</Button>
        <Box h={5} />
        <CSVUpload setData={props.setStudents} />
        <Box pt={10} />
      </ModalBody>

    </ModalContent>


  let tableForm = <Formik
    initialValues={{ students: props.students }}
    validateOnMount
    onSubmit={(values, actions) => {
      setTimeout(() => {
        props.onSubmit(token, values, () => {
          actions.setSubmitting(false)
          actions.resetForm()
          props.onClose()
        })
      }, 1000)
    }}
  >
    {({ values, isValid, isSubmitting }) => {
      return <Form>
        <ModalContent borderRadius={10} border={BORDER_DEFAULT}>

          <ModalHeader>{props.title}</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Text>{props.description}</Text>
            <Box pt={10} />

            <FieldArray name="students">
              {arrayHelpers => (
                <div>
                  <Table size="small" variant="unstyled">
                    <Thead>
                      <Tr>
                        <Th fontSize="xs">Identifier</Th>
                        <Th fontSize="xs">Preferred Name</Th>
                        <Th fontSize="xs">Last Name</Th>
                        <Th fontSize="xs">Year Level</Th>
                        <Th />
                      </Tr>
                    </Thead>
                    <Tbody>
                      {values.students && values.students.length > 0 ? (
                        values.students.map((item: Student, index) => (
                          <Tr key={index}>
                            <Td>
                              <Input size="sm" name={`students[${index}].identifier`} defaultValue={item.identifier} />
                            </Td>
                            <Td>
                              <Input size="sm" name={`students[${index}].preferredName`} defaultValue={item.preferredName} />
                            </Td>
                            <Td>
                              <Input size="sm" name={`students[${index}].lastName`} defaultValue={item.lastName} />
                            </Td>
                            <Td>
                              <Input size="sm" name={`students[${index}].yearLevel`} defaultValue={item.yearLevel} type="number" />
                            </Td>
                            <Td>
                              <IconButton icon={<BiX />} size="sm" onClick={() => arrayHelpers.remove(index)} aria-label="" />
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td>No Items to show</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                  <Box h={5} />
                  <Button size="sm"
                    onClick={() => arrayHelpers.push(props.initialValues || {})}
                  >Add</Button>
                </div>
              )}
            </FieldArray>
            <Box pt={10} />
          </ModalBody>

          <ModalFooter>
            <CancelButton w="25%" />
            <Spacer />
            <SaveButton testId="save-button" w="25%" isLoading={isSubmitting} isDisabled={!isValid} />
          </ModalFooter>

        </ModalContent>
      </Form>
    }}
  </Formik>

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size="lg" isCentered>
      <ModalOverlay bg="transparent" backdropFilter="blur(12px)" />
      {props.students.length === 0 ? importStudentsFromCSV : tableForm}
    </Modal>
  )
}
