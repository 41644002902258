import { useNavigate } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { AnalyticaTable } from '../../table';
import { addOrg, deleteOrg, fetchOrgs, fetchOrgsWithSearch, updateOrg } from '../../../data/request';
import { Org } from '../../../data/classes';
import { AnalyticaForm, DeleteForm } from '../../form';
import { ICON_CLASS, ICON_DELETE, ICON_EDIT, ICON_ORG, ICON_STAFF } from '../../../theme';
import { AccountContext } from '../../Account';
import { AnalyticaModal } from '../../modal';
import { TextInput } from '../../form/input';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { AddButton, TableIconButton } from '../../button';

export const Orgs = () => {

  const navigate = useNavigate()
  const [render, setRender] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  const { setPageTitle } = useContext(AccountContext)
  setPageTitle("Organisations")

  const [item, setItem] = useState<Org | null>(null)

  const closeAndRender = (close: () => void) => {
    close()
    setRender(!render)
  }

  const add = () => {
    setItem(null)
    onOpen()
  }

  const edit = (org: Org) => {
    setItem(org)
    onOpen()
  }

  const deleteItem = (org: Org) => {
    setItem(org)
    onOpenDelete()
  }

  const viewClasses = (org: Org) =>
    <TableIconButton
      icon={ICON_CLASS}
      testId="view-classes"
      onClick={() => navigate("./" + org.id + "/classes")}
    />

  const viewStaff = (org: Org) =>
    <TableIconButton
      icon={ICON_STAFF}
      testId="view-staff"
      onClick={() => navigate("./" + org.id + "/staff")}
    />

  const editOrgButton = (org: Org) =>
    <TableIconButton
      icon={ICON_EDIT}
      testId="edit-org"
      onClick={() => edit(org)}
    />

  const deleteOrgButton = (org: Org) =>
    <TableIconButton
      icon={ICON_DELETE}
      testId="delete-student"
      onClick={() => deleteItem(org)}
    />

  let columns = [
    { id: "name", name: "Name" },
    { id: "numClasses", name: "#Classes" },
    { id: "numStudents", name: "#Students" },
    { id: "primaryAddress", name: "Primary Address" },
    { name: "Classes", component: viewClasses },
    { name: "Staff", component: viewStaff },
    { name: "Edit", component: editOrgButton },
    { name: "Delete", component: deleteOrgButton },
  ]

  return (
    <>
      <AnalyticaTable
        columns={columns}
        icon={ICON_ORG}
        fetchItems={fetchOrgs}
        fetchItemsWithSearch={fetchOrgsWithSearch}
        render={render}
        searchable
        searchOnChange
      >
        <AddButton
          title="Add Organisation"
          onClick={add}
          testId="add-org-button"
          rightIcon={<AddIcon />}
        />

      </AnalyticaTable>

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => closeAndRender(onClose)}
      >
        <AnalyticaForm
          name="org-form"
          title='Add an Organisation'
          description='Add a new Organisation to Rubric Analytica.'
          initialValues={item || {}}
          onSubmit={item ? updateOrg : addOrg}
          onClose={() => closeAndRender(onClose)}
        >
          <TextInput
            name="name"
            testId="org-name-input"
            title="Organisation Name"
            validation={validateName}
          />
          <TextInput
            name="primaryAddress"
            testId="org-primary-address-input"
            title="Primary Address"
            optional
          />
        </AnalyticaForm>
      </AnalyticaModal>

      <AnalyticaModal
        isOpen={isOpenDelete}
        onOpen={onOpenDelete}
        onClose={() => closeAndRender(onCloseDelete)}
      >
        <DeleteForm
          name='org-delete-modal'
          description={`Are you sure you want to delete Organisation - ${item?.name}?`}
          initialValues={item || {}}
          onSubmit={deleteOrg}
          onClose={() => closeAndRender(onCloseDelete)}
        />
      </AnalyticaModal>
    </>
  )
}
