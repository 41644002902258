import { FormControl } from "@chakra-ui/react"
import { FormikProps } from "formik"
import React, { ReactNode } from "react"

interface Props {
  form: FormikProps<any>
  name: string
  children: ReactNode | ReactNode[]
}

export const Control: React.FC<Props> = ({ form, name, children }) => (
  <FormControl isInvalid={form.errors[name] && form.touched[name] ? true : false} >
    {children}
  </FormControl>
)
