import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { BORDER_DEFAULT } from "../../theme";
import { createContext } from "react";

interface ModalContextType {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const ModalContext = createContext<ModalContextType>({} as ModalContextType);

interface Props {
  children: ReactNode | ReactNode[]
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
export const AnalyticaModal: React.FC<Props> = (props) => {

  // THIS! Because it won't let me pass them directly from props ¯\_(ツ)_/¯
  const [isOpen] = useState<boolean>(props.isOpen)
  const [onOpen] = useState<() => void>(props.onOpen)
  const [onClose] = useState<() => void>(props.onClose)

  return (
    <ModalContext.Provider value={{
      isOpen,
      onOpen,
      onClose,
    }}>
      <Modal onClose={props.onClose} isOpen={props.isOpen} size="lg" isCentered>
        <ModalOverlay bg="transparent" backdropFilter="blur(12px)" />
        <ModalCloseButton />
        {props.children}
      </Modal>
    </ModalContext.Provider>
  )
}

