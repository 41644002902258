import axios, { AxiosRequestConfig } from 'axios';
import { Admin, Class, Org, Result, StaffMember, Student, Test } from './classes';

declare const window: any;

const SERVER_API_URI = process.env.NODE_ENV === 'production' ? window.env.SERVER_API_URI
  : process.env.REACT_APP_SERVER_API_URI || "http://127.0.0.1:8080/api"

const headers = (token: string) => {
  return { 'Authorization': `Bearer ${token}`, }
}

const config = (token: string) => {
  return { headers: headers(token) }
}

const toServerPath = (path: string) => {
  return `${SERVER_API_URI}/${path}`
}

const params = (start: number, limit: number, search?: string) => {
  let ret = ""
  if (start || limit || search) {
    ret = "?"
  }
  if (start) {
    ret += `start=${start}`
  }
  if (limit) {
    if (start) {
      ret += "&"
    }
    ret += `limit=${limit}`
  }
  if (search) {
    if (start || limit) {
      ret += "&"
    }
    ret += `&search=${search}`
  }
  return ret
}

export async function addOrg(token: string, o: Org, callback: Function) {
  postData(`v1/org`, o, token, callback)
}

export async function addClass(token: string, c: Class, callback: Function) {
  postData(`v1/class`, c, token, callback)
}

export async function inviteAdmin(token: string, a: Admin, callback: Function) {
  postData(`v1/admin/invite`, a, token, callback)
}

export async function inviteStaff(token: string, s: StaffMember, callback: Function) {
  postData(`v1/staff/invite`, s, token, callback)
}

export async function addStudent(token: string, s: Student, classId: string, callback: Function) {
  postData(`v1/class/${classId}/students`, [s], token, callback)
}

export async function upsertResult(token: string, r: Result, testId: string, studentId: string) {
  postData(`v1/tests/${testId}/students/${studentId}/results`, r, token)
}

export async function generateTest(token: string, classId: string, callback: Function) {
  postData(`v1/class/${classId}/test`, {}, token, callback)
}

async function postData(path: string, data: any, token: string, callback?: Function) {
  axios.post(toServerPath(path), data, config(token))
    .then(
      () => {
        if (callback) callback()
      }
    )
}

export async function deleteOrg(token: string, orgId: string, callback: Function) {
  deleteData(`v1/org/${orgId}`, token, callback)
}

export async function deleteClass(token: string, classId: string, callback: Function) {
  deleteData(`v1/class/${classId}`, token, callback)
}

export async function deleteStaff(token: string, staffId: string, callback: Function) {
  deleteData(`v1/staff/${staffId}`, token, callback)
}

export async function deleteStudent(token: string, studentId: string, callback: Function) {
  deleteData(`v1/student/${studentId}`, token, callback)
}

export async function deleteTest(token: string, testId: string, callback: Function) {
  deleteData(`v1/test/${testId}`, token, callback)
}

async function deleteData(path: string, token: string, callback?: Function) {
  axios.delete(toServerPath(path), config(token))
    .then(
      () => {
        if (callback) callback()
      }
    )
}

export async function updateOrg(token: string, o: Org, callback: Function) {
  putData(`v1/org/${o.id}`, o, token, callback)
}

export async function updateClass(token: string, c: Class, callback: Function) {
  putData(`v1/class/${c.id}`, c, token, callback)
}

export async function updateAdmin(token: string, a: Admin, callback: Function) {
  console.log("A", a)
  putData(`v1/admin/${a.id}`, a, token, callback)
}

export async function updateStaff(token: string, s: StaffMember, callback: Function) {
  putData(`v1/staff/${s.id}`, s, token, callback)
}

export async function updateStudent(token: string, s: Student, callback: Function) {
  putData(`v1/student/${s.id}`, s, token, callback)
}

export async function updateTest(token: string, t: Test, callback: Function) {
  putData(`v1/test/${t.id}`, t, token, callback)
}

export async function completeTest(token: string, t: Test, callback: Function) {
  putData(`v1/test/${t.id}/complete`, t, token, callback)
}

async function putData(path: string, data: any, token: string, callback?: Function) {
  axios.put(toServerPath(path), data, config(token))
    .then(
      () => {
        if (callback) callback()
      }
    )
}

export async function fetchOrgs(token: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/org/all${params(start, limit)}`, token, setState)
}

export async function fetchOrgsWithSearch(token: string, search: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/org/all${params(start, limit)}&search=${search}`, token, setState)
}

export async function fetchOrgById(token: string, orgId: string, setState: Function) {
  fetchData(`v1/org/${orgId}`, token, setState)
}

export async function fetchClassById(token: string, classId: string, setState: Function) {
  fetchData(`v1/class/${classId}`, token, setState)
}

export async function fetchClassesByOrgId(token: string, orgId: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/classes${params(start, limit)}`, token, setState)
}

export async function fetchClassesByOrgIdWithSearch(token: string, orgId: string, search: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/classes${params(start, limit, search)}`, token, setState)
}

export async function fetchAdmins(token: string, setState: Function) {
  fetchData(`v1/admin/all`, token, setState)
}

export async function fetchAdminsWithSearch(token: string, search: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/admin/all${params(start, limit, search)}`, token, setState)
}

export async function fetchAdminById(token: string, adminId: string, setState: Function) {
  fetchData(`v1/admin/${adminId}`, token, setState)
}

export async function fetchAdminByCognitoId(token: string, cognitoId: string, setState: Function) {
  fetchData(`v1/admin/cognito/${cognitoId}`, token, setState)
}

export async function fetchStaffByCognitoId(token: string, cognitoId: string, setState: Function) {
  fetchData(`v1/staff/cognito/${cognitoId}`, token, setState)
}

export async function fetchStaffById(token: string, staffId: string, setState: Function) {
  fetchData(`v1/staff/${staffId}`, token, setState)
}

export async function fetchStaffByOrgId(token: string, orgId: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/staff${params(start, limit)}`, token, setState)
}

export async function fetchStaffByOrgIdWithSearch(token: string, orgId: string, search: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/org/${orgId}/staff${params(start, limit, search)}`, token, setState)
}

export async function fetchStudentById(token: string, studentId: string, setState: Function) {
  fetchData(`v1/student/${studentId}`, token, setState)
}

export async function fetchTestById(token: string, testId: string, setState: Function) {
  fetchData(`v1/test/${testId}`, token, setState)
}

export async function fetchResultById(token: string, resultId: string, setState: Function) {
  fetchData(`v1/result/${resultId}`, token, setState)
}

export async function fetchStudentsByClassId(token: string, classId: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/class/${classId}/students${params(start, limit)}`, token, setState)
}

export async function fetchStudentsByClassIdWithSearch(token: string, classId: string, search: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/class/${classId}/students${params(start, limit, search)}`, token, setState)
}

export async function fetchTests(token: string, setState: Function) {
  fetchData(`v1/tests`, token, setState)
}

export async function fetchTestsByClassId(token: string, classId: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/class/${classId}/tests${params(start, limit)}`, token, setState)
}

export async function fetchResults(token: string, setState: Function) {
  fetchData(`v1/results`, token, setState)
}

export async function fetchResultsByTestId(token: string, testId: string, setState: Function, start: number, limit: number) {
  fetchData(`v1/tests/${testId}/results${params(start, limit)}`, token, setState)
}

export async function fetchResultsByStudentId(token: string, studentId: string, setState: Function) {
  fetchData(`v1/student/${studentId}/results`, token, setState)
}

export async function fetchDashboardAggregations(token: string, setState: Function) {
  fetchData(`v1/dashboard`, token, setState)
}

export async function fetchClassFeedback(token: string, classId: string, setState: Function) {
  fetchData(`v1/class/${classId}/feedback`, token, setState)
}

async function fetchData(path: string, token: string, callback?: Function) {
  axios.get(toServerPath(path), config(token))
    .then(
      (result) => {
        if (callback) {
          callback(result.data);
        }
      },
      (error) => {
        console.error(error)
      }
    )
}
export async function downloadTestPDF(token: string, testId: string, filename: string) {
  downloadPDF(`v1/test/${testId}/pdf`, filename, token)
}

export async function downloadImportTemplateCSV(token: string, filename: string) {
  downloadCSV('v1/template/import', filename, token)
}

async function downloadPDF(path: string, filename: string, token: string) {
  downloadFile(path, filename, 'application/pdf', token)
}

async function downloadCSV(path: string, filename: string, token: string) {
  downloadFile(path, filename, 'text/csv', token)
}

async function downloadFile(path: string, filename: string, filetype: string, token: string) {
  const axiosConfig: AxiosRequestConfig = {
    responseType: "blob",
    headers: headers(token)
  }
  axios.get(toServerPath(path), axiosConfig)
    .then(
      (response) => {
        const fileURL = window.URL.createObjectURL(new File([new Blob([response.data])], filename, { type: filetype }))
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename
        alink.click();
      },
      (error) => {
        console.error(error)
      }
    )
}

