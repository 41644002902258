import { Box, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Spacer, Text } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { ReactNode, useContext } from "react";
import { BORDER_DEFAULT } from "../../theme";
import { AccountContext } from "../Account";
import { CancelButton } from "../button";
import { DeleteButton } from "../button/DeleteButton";

interface DeleteFormProps {
  name: string
  description: string
  initialValues: object
  onSubmit: (...args: any[]) => void
  onClose: () => void
}

export const DeleteForm = (props: DeleteFormProps) => {

  const { token } = useContext(AccountContext)

  return (
    <Formik
      initialValues={props.initialValues || {}}
      validateOnMount
      onSubmit={(values: any, actions) => {
        setTimeout(() => {
          props.onSubmit(token, values.id, () => {
            actions.setSubmitting(false)
            props.onClose()
          })
        }, 1000)
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <ModalContent borderRadius={10} border={BORDER_DEFAULT}>

            <ModalHeader>Are you sure?</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Text>{props.description}</Text>
              <Box pt={10} />
            </ModalBody>

            <ModalFooter>
              <CancelButton w="25%" />
              <Spacer />
              <DeleteButton
                w="25%"
                testId="save-button"
                isLoading={isSubmitting}
                isDisabled={!isValid} />
            </ModalFooter>

          </ModalContent>
        </Form>
      )}
    </Formik>
  )
}

