const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const NAME_REGEX = /^[a-zA-Z0-9_\- ']{1,30}$/
const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\W)(?=.*\d)[A-Za-z\d\W]{8,}$/

const EMPTY_VALUE_MSG = 'Value must not be empty'
const INVALID_EMAIL_MSG = 'Value must be a valid email address'
const INVALID_NAME_MSG = 'Value must contain only letters, numbers, underscores, dashes, and spaces'
const INVALID_PASSWORD_MSG = 'Passwords must be at least 8 characters long and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.'
const PASSWORD_NON_MATCH = 'Passwords must match'
const INVALID_DATE_MSG = 'Value must be a valid Date'

export const validateOptional = (value: any) => {
  let error
  return error
}

export const validateNonEmpty = (value: any) => {
  let error
  if (!value) {
    error = EMPTY_VALUE_MSG
  }
  return error
}

export const validateName = (value: string) => {
  let error
  if (!value) {
    error = EMPTY_VALUE_MSG
  } else if (!String(value).toLowerCase().match(NAME_REGEX)) {
    error = INVALID_NAME_MSG
  }
  return error
}

export const validateEmail = (value: string) => {
  let error
  if (!value) {
    error = EMPTY_VALUE_MSG
  } else if (!String(value).toLowerCase().match(EMAIL_REGEX)) {
    error = INVALID_EMAIL_MSG
  }
  return error
};

export const validatePassword = (value: string) => {
  let error
  if (!value) {
    error = EMPTY_VALUE_MSG
  } else if (!String(value).match(PASSWORD_REGEX)) {
    error = INVALID_PASSWORD_MSG
  }
  return error
};

export const validateConfirmPassword = (prev: string) => (value: string) => {
  let error
  if (prev !== value) {
    error = PASSWORD_NON_MATCH
  }
  return error
};

export const validateDate = (value: any) => {
  let error
  if (!value) {
    error = EMPTY_VALUE_MSG
  }
  if (!isNaN(value) && value instanceof Date) { // isNaN wont accept a date in typescript, use date.getTime() instead to produce a number
    return error
  }
  return INVALID_DATE_MSG
}
