import React, { ReactNode, createContext, useEffect, useState } from "react"
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute, CognitoUserSession, CognitoIdToken } from "amazon-cognito-identity-js"

declare const window: any;

interface CurrentUser {
  [key: string]: any
}

interface AccountContextType {
  token: string | null,
  user: CurrentUser | null,
  validSession: boolean,
  session: CognitoUserSession | null,
  verified: boolean,
  signUp: Function,
  verify: Function,
  resetPassword: Function,
  resendConfirmationCode: Function,
  authenticate: Function,
  getSession: Function,
  updateDetails: Function,
  logout: Function,
  getUserRoles: Function,
  isAdmin: Function,
  isStaff: Function,
  pageTitle: string,
  setPageTitle: Function,
  rerender: Function,
}

export const AccountContext = createContext<AccountContextType>({} as AccountContextType);
const GROUPS = "cognito:groups"

const poolData = {
  ClientId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_CLIENT_ID : process.env.REACT_APP_COGNITO_CLIENT_ID,
  UserPoolId: process.env.NODE_ENV === 'production' ? window.env.COGNITO_USER_POOL_ID : process.env.REACT_APP_COGNITO_USER_POOL_ID
}

interface Props {
  children: ReactNode | ReactNode[]
}

export const Account: React.FC<Props> = (props: Props) => {

  const [validSession, setValidSession] = useState<boolean>(localStorage.getItem("validSession") === 'true' || false)
  const [session, setSession] = useState<CognitoUserSession | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<CurrentUser | null>(null)
  const [verified, setVerified] = useState<boolean>(true)

  const [userPool] = useState<CognitoUserPool>(new CognitoUserPool(poolData))
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)

  const [pageTitle, setPageTitle] = useState<string>("Dashboard")

  const [render, setRender] = useState<boolean>(false)

  useEffect(() => {
    localStorage.setItem("validSession", validSession.toString())
    if (!session) {
      getSession()
      return
    }
    console.log(user)
    console.log(cognitoUser)
    if (session.getAccessToken()) {
      setToken(session.getAccessToken().getJwtToken())
    }
    if (session.getIdToken()) {
      setUser(session.getIdToken().payload)
      setVerified(session.getIdToken().payload.email_verified)
    }
  }, [session, validSession, token, user, cognitoUser])

  const signUp = async (
    Email: string,
    Password: string,
    GivenName: string,
    FamilyName: string,
    onSuccess: Function,
    onError: Function
  ) => {
    userPool.signUp(Email, Password, [
      new CognitoUserAttribute({ Name: "given_name", Value: GivenName }),
      new CognitoUserAttribute({ Name: "family_name", Value: FamilyName }),
    ], [], (err, data) => {
      if (err) {
        onError(err)
      }
      onSuccess(data)
    })
  }

  const authenticate = async (
    Email: string,
    Password: string,
    onSuccess: Function,
    onError: Function,
    onNewPasswordRequired: Function
  ) => {
    const u = new CognitoUser({ Username: Email, Pool: userPool })
    const authDetails = new AuthenticationDetails({ Username: Email, Password })
    u.authenticateUser(authDetails, {
      onSuccess: (data) => {
        getSession()
        onSuccess(data)
      },
      onFailure: (err) => {
        onError(err)
      },
      newPasswordRequired: () => {
        setCognitoUser(u)
        getSession()
        onNewPasswordRequired()
      },
    })
  }

  const verify = async (
    Code: string,
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.confirmRegistration(Code, true, (err: Error, data) => {
      if (err) {
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const resetPassword = async (
    NewPassword: string,
    onSuccess: Function,
    onError: Function
  ) => {
    console.log(cognitoUser)
    if (!cognitoUser) {
      return
    }
    cognitoUser.completeNewPasswordChallenge(NewPassword, {}, {
      onSuccess: (data) => {
        onSuccess(data)
      },
      onFailure: (err) => {
        onError(err)
      },
    })
  }

  const resendConfirmationCode = async (
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.resendConfirmationCode((err: Error | undefined, data: any) => {
      if (err) {
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const getSession = async () => {
    let u = userPool.getCurrentUser()
    if (!u) {
      console.log("No Valid User!")
      return
    }
    u.getSession((err: Error, sess: CognitoUserSession | null) => {
      if (err) {
        console.error(err)
        return
      }
      console.log("SESSION", sess)
      setSession(sess ? sess : null)
      setCognitoUser(u)
      setValidSession(true)
    })
  }

  const updateDetails = async (
    GivenName: string,
    FamilyName: string,
    onSuccess: Function,
    onError: Function
  ) => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.updateAttributes([
      new CognitoUserAttribute({ Name: "given_name", Value: GivenName }),
      new CognitoUserAttribute({ Name: "family_name", Value: FamilyName }),
    ], (err, data) => {
      if (err) {
        console.error(err)
        onError(err)
        return
      }
      onSuccess(data)
    })
  }

  const logout = () => {
    if (!cognitoUser) {
      return
    }
    cognitoUser.signOut()
    setSession(null)
    setValidSession(false)
    setCognitoUser(null)
    localStorage.clear();
  }

  const getUserRoles = () => {
    return (user && user[GROUPS]) ? user[GROUPS] : []
  }

  const isAdmin = () => {
    return user && user[GROUPS] && user[GROUPS].includes("admin")
  }

  const isStaff = () => {
    return user && user[GROUPS] && user[GROUPS].includes("staff")
  }

  const rerender = () => {
    setRender(!render)
  }

  return (
    <AccountContext.Provider value={{
      token,
      user,
      validSession,
      session,
      verified,
      signUp,
      verify,
      resetPassword,
      resendConfirmationCode,
      authenticate,
      getSession,
      updateDetails,
      logout,
      getUserRoles,
      isAdmin,
      isStaff,
      pageTitle,
      setPageTitle,
      rerender,
    }}>
      {props.children}
    </AccountContext.Provider>
  )
}
