import React from 'react'
import { TextInput, TextInputProps } from './TextInput'
import { validateEmail } from '../../../data/validations'

export interface EmailInputProps extends Omit<TextInputProps, 'name' | 'title' | 'validation'> {
  name?: string
  title?: string
  validation?: (arg0: string) => string | undefined
}

export const EmailInput = (props: EmailInputProps) => (
  <TextInput
    name={props.name || "email"}
    title={props.title || "Email Address"}
    validation={props.validation || validateEmail}
    {...props}
  />
)
