import { Box, Grid, GridItem } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { AccountContext } from "./Account";
import React from 'react'
import { Loading } from "./loading";
import { Sidebar } from "./sidebar";
import { Header } from "./header";
import { BORDER_DEFAULT, COLOUR_PRIMARY_LIGHT } from "../theme";

const R = require('ramda');

interface Props {
  roles: string[]
}

export const ProtectedRoute: React.FC<Props> = ({ roles }) => {

  console.log("PROTECTED_ROUTE")

  const navigate = useNavigate();
  const { verified, validSession, getUserRoles } = useContext(AccountContext)

  useEffect(() => {
    console.log("Verified: ", verified)
    // ensure we have a valid session and are verified
    if (!verified) {
      return navigate("/verify", { replace: true });
    }
    console.log("Valid Session: ", validSession)
    if (!validSession) {
      return navigate("/login", { replace: true });
    }
  }, [navigate, verified, validSession]);

  const containsAtLeastOne = (userRoles: string[], requiredRoles: string[]) => {
    console.log("Checking User Roles", userRoles, requiredRoles)
    return R.intersection(userRoles, requiredRoles).length > 0;
  }

  // make  sure user has all necessary roles for page
  const userRoles = getUserRoles()
  if (R.isEmpty(userRoles)) {
    return <Loading />
  }
  if (!containsAtLeastOne(userRoles, roles)) {
    navigate("/404", { replace: true });
    return <Loading />
  }

  console.log("Loading Protected Route")

  return (
    <Grid
      minHeight='100vh'
      templateRows='repeat(11, 1fr)'
      templateColumns='repeat(7, 1fr)'
      bg={COLOUR_PRIMARY_LIGHT}
    >
      <GridItem rowSpan={11} colSpan={1} bg='white' borderRight={BORDER_DEFAULT}>
        <Sidebar />
      </GridItem>
      <GridItem rowSpan={1} colSpan={6}>
        <Header />
      </GridItem>
      <GridItem rowSpan={10} colSpan={6} >
        <Box p={5}>
          <Outlet />
        </Box>
      </GridItem>
    </Grid>
  )
};
