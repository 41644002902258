import { AddIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { fetchOrgById, fetchStaffByCognitoId, fetchStaffByOrgId, fetchStaffByOrgIdWithSearch, inviteStaff } from '../../../data/request';
import React from 'react'
import { AccountContext } from '../../Account';
import { Org, StaffMember } from '../../../data/classes';
import { useDisclosure } from '@chakra-ui/react';
import { AnalyticaTable } from '../../table';
import { Loading } from '../../loading';
import { AnalyticaForm } from '../../form';
import { validateEmail } from '../../../data/validations';
import { ICON_STAFF } from '../../../theme';
import { AnalyticaModal } from '../../modal';
import { TextInput } from '../../form/input';
import { AddButton } from '../../button';
import { fullname } from '../../../data/functions';

// Wierd naming bug when this is named Staff ¯\_(ツ)_/¯
export const StaffMembers = () => {

  let { orgId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [render, setRender] = useState<boolean>(false)

  const [org, setOrg] = useState<Org | null>();
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)

  const { token, user, isStaff, setPageTitle } = useContext(AccountContext)
  setPageTitle("Staff")

  const userIsStaff = isStaff()

  useEffect(() => {
    let oId = orgId
    if (token) {
      if (userIsStaff && !staffMember && user) {
        fetchStaffByCognitoId(token, user.sub, setStaffMember)
      }
      if (staffMember) {
        oId = staffMember.orgId
      }
      if (oId) {
        fetchOrgById(token, oId, setOrg)
      }
    }
  }, [orgId, token, staffMember, user, userIsStaff]);

  if (!org) {
    return <Loading />
  }

  const closeAndRender = (close: () => void) => {
    close()
    setRender(!render)
  }

  const fetchStaffWithSearch = (token: string, search: string, setItems: Function, start: number, limit: number) => {
    if (!org) {
      return
    }
    fetchStaffByOrgIdWithSearch(token, org.id, search, setItems, start, limit);
  }

  const fetchStaff = (token: string, setItems: Function, start: number, limit: number) => {
    if (!org) {
      return
    }
    fetchStaffByOrgId(token, org.id, setItems, start, limit);
  }

  // const viewClasses = (s: StaffMember) =>
  //   <TableIconButton icon={ICON_CLASS}
  //     testId="view-classes"
  //     onClick={() => navigate("./" + s.id + "/classes")}
  //   />

  let columns = [
    { name: "Name", component: fullname },
    { id: "email", name: "Email Address" },
    { id: "numClasses", name: "#Classes" },
    { id: "numStudents", name: "#Students" },
    // TODO: This is bad, probs not working
    // { name: "Classes", component: viewClasses }
  ]

  return (
    <>
      <AnalyticaTable
        columns={columns}
        icon={ICON_STAFF}
        fetchItems={fetchStaff}
        fetchItemsWithSearch={fetchStaffWithSearch}
        render={render}
        searchable
        searchOnChange
      >
        <AddButton
          title='Invite Staff'
          testId='invite-staff-button'
          onClick={onOpen}
          rightIcon={<AddIcon />}
        />

      </AnalyticaTable>

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => closeAndRender(onClose)}
      >
        <AnalyticaForm
          initialValues={{ orgId: org.id }}
          onSubmit={inviteStaff}
          onClose={() => closeAndRender(onClose)}
          name='invite-staff'
          title='Invite a Staff Member'
          description={`Invite a Staff Member to join ${org.name}. The new user will receive an email with their login details.`}
        >
          <TextInput
            name="email"
            title="Email Address"
            testId='staff-email-input'
            validation={validateEmail}
          />
        </AnalyticaForm>
      </AnalyticaModal>
    </>
  )
}
