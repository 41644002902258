import React from "react"
import { Account } from "./components/Account"
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from "./theme"
import { Outlet } from "react-router-dom"

export const App = () => {
  console.log("APP")
  return (
    <ChakraProvider theme={theme}>
      <Account>
        <Outlet />
      </Account>
    </ChakraProvider >
  )
}
