import { useNavigate, useParams } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { addClass, deleteClass, fetchAdminByCognitoId, fetchClassesByOrgId, fetchClassesByOrgIdWithSearch, fetchOrgById, fetchStaffByCognitoId, updateClass } from '../../../data/request';
import { useContext, useEffect, useState } from 'react';
import { AnalyticaTable } from '../../table';
import { AccountContext } from '../../Account';
import { Class, Org, StaffMember } from '../../../data/classes';
import { Box, IconButton, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { Loading } from '../../loading';
import { AnalyticaForm, DeleteForm } from '../../form';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { ICON_CLASS, ICON_DELETE, ICON_EDIT, ICON_STUDENT, ICON_TEST } from '../../../theme';
import { AnalyticaModal } from '../../modal';
import { IntegerInput, TextInput } from '../../form/input';
import { AddButton, TableIconButton } from '../../button';

export const Classes = () => {

  const navigate = useNavigate()
  const [render, setRender] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  let { orgId } = useParams();
  const [org, setOrg] = useState<Org | null>()
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)

  const { token, user, isStaff, setPageTitle } = useContext(AccountContext)
  setPageTitle("Classes")

  const [item, setItem] = useState<Class | null>(null)

  const userIsStaff = isStaff()

  useEffect(() => {
    let oId = orgId
    if (token) {
      if (userIsStaff && !staffMember && user) {
        fetchStaffByCognitoId(token, user.sub, setStaffMember)
      }
      if (staffMember) {
        oId = staffMember.orgId
      }
      if (oId) {
        fetchOrgById(token, oId, setOrg)
      }
    }
  }, [orgId, token, staffMember, user, userIsStaff]);

  if (!org) {
    return <Loading />
  }

  const closeAndRender = (close: () => void) => {
    close()
    setRender(!render)
  }

  const fetchClassesWithSearch = (token: string, search: string, setItems: Function, start: number, limit: number) =>
    fetchClassesByOrgIdWithSearch(token, org.id, search, setItems, start, limit);

  const fetchClasses = (token: string, setItems: Function, start: number, limit: number) =>
    fetchClassesByOrgId(token, org.id, setItems, start, limit);

  const add = () => {
    setItem(null)
    onOpen()
  }

  const edit = (c: Class) => {
    setItem(c)
    onOpen()
  }

  const deleteItem = (c: Class) => {
    setItem(c)
    onOpenDelete()
  }

  const viewStudentsButton = (c: Class) =>
    <TableIconButton
      icon={ICON_STUDENT}
      testId="view-students"
      onClick={() => navigate("./" + c.id + "/students")}
    />

  const viewTestsButton = (c: Class) =>
    <TableIconButton
      icon={ICON_TEST}
      testId="view-tests"
      onClick={() => navigate("./" + c.id + "/tests")}
    />

  const editClassButton = (c: Class) =>
    <TableIconButton
      icon={ICON_EDIT}
      testId="edit-class"
      onClick={() => edit(c)}
    />

  const deleteClassButton = (c: Class) =>
    <TableIconButton
      icon={ICON_DELETE}
      testId="delete-class"
      onClick={() => deleteItem(c)}
    />

  let columns = [
    { id: "name", name: "Name" },
    { id: "yearLevel", name: "Year Level" },
    { id: "numStudents", name: "#Students" },
    { id: "avgScore", name: "Avg. Score", format: "percentage" },
    { id: "avgTimeRemaining", name: "Avg. Time Remaining (s)" },
    { name: "Students", component: viewStudentsButton },
    { name: "Tests", component: viewTestsButton },
    { name: "Edit", component: editClassButton },
    { name: "Delete", component: deleteClassButton },
  ]

  return (
    <>
      <AnalyticaTable
        columns={columns}
        icon={ICON_CLASS}
        fetchItems={fetchClasses}
        fetchItemsWithSearch={fetchClassesWithSearch}
        render={render}
        searchable
        searchOnChange
      >
        <AddButton
          title="Add Class"
          onClick={add}
          testId="add-class-button"
          rightIcon={<AddIcon />}
        />

      </AnalyticaTable>

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => closeAndRender(onClose)}
      >
        <AnalyticaForm
          initialValues={item || {
            orgId: org.id,
            staffId: staffMember && staffMember.id
          }}
          onSubmit={item ? updateClass : addClass}
          onClose={() => closeAndRender(onClose)}
          name='add-class'
          title='Add a Class'
          description={`Add a new Class to ${org.name}`}
        >
          <TextInput
            name="name"
            title="Name"
            testId='class-name-input'
            validation={validateName}
          />
          <Box pt={4} />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='class-year-level-input'
            validation={validateNonEmpty}
            min={1}
            max={12}
          />
        </AnalyticaForm>
      </AnalyticaModal>

      <AnalyticaModal
        isOpen={isOpenDelete}
        onOpen={onOpenDelete}
        onClose={() => closeAndRender(onCloseDelete)}
      >
        <DeleteForm
          name='class-delete-modal'
          description={`Are you sure you want to delete Class - ${item?.name}?`}
          initialValues={item || {}}
          onSubmit={deleteClass}
          onClose={() => closeAndRender(onCloseDelete)}
        />
      </AnalyticaModal>
    </>
  )
}
