import { useContext, useState } from "react"
import { Button } from "@chakra-ui/react"
import { AccountContext } from "./Account"
import { useNavigate } from "react-router-dom";
import { validateConfirmPassword, validateEmail, validateName, validatePassword } from "../data/validations";
import React from 'react'
import { AuthForm } from "./form";
import { ConfirmPasswordInput, EmailInput, PasswordInput, TextInput } from "./form/input";

export const Signup = () => {

  const navigate = useNavigate();
  const { signUp } = useContext(AccountContext)
  const [error, setError] = useState<string | null>(null)

  const onSuccess = (data: any) => {
    navigate("/verify", { replace: true, state: { email: data.user.username } });
  }

  const onError = (err: Error) => {
    setError("Error Adding User")
  }

  const onSubmit = (values: any) => {
    signUp(values.email, values.password, values.preferredName, values.lastName, onSuccess, onError)
  }

  const onMoveToLogin = () => {
    navigate("/login", { replace: true });
  }

  return (
    <AuthForm
      name="signup"
      title="Sign Up"
      buttonText="Sign Up"
      error={error}
      onSubmit={onSubmit}
    >
      <TextInput
        name="preferredName"
        title="Preferred Name"
        testId="preferred-name-input"
        validation={validateName}
      />
      <TextInput
        name="lastName"
        title="Last Name"
        testId="last-name-input"
        validation={validateName}
      />
      <EmailInput
        testId="email-input"
      />
      <PasswordInput
        testId="password-input"
      />
      <ConfirmPasswordInput
        testId="confirm-password-input"
      />
      <Button variant="link" onClick={onMoveToLogin}>Login</Button>
    </AuthForm>
  )
}

export default Signup
