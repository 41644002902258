import { Box, Center, Grid, GridItem, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { fetchDashboardAggregations } from '../../data/request';
import { AccountContext } from '../Account';
import React from 'react';
import { StatTile } from './stat';
import { Loading } from '../loading';

interface Data {
  avgProgress: object[]
  topLearners: object[]
  totalClasses: number
  totalStudents: number
  testsLastMonth: number
  avgScore: number
}

export const Dashboard = () => {

  const [data, setData] = useState<Data | null>(null)
  const { token, setPageTitle } = useContext(AccountContext)
  setPageTitle("Dashboard")

  useEffect(() => {
    if (token) {
      fetchDashboardAggregations(token, setData)
    }
  }, [token]);

  if (!token) {
    return <Loading />
  }

  return (
    <>
      <Box pt={10} />
      {data ?
        <Grid
          templateRows='repeat(3, 1fr)'
          templateColumns='repeat(4, 1fr)'
          gap={6}
        >
          <GridItem rowSpan={2} colSpan={1} >
            <StatTile
              title="Total Active Classes"
              value={data.totalClasses}
            />
          </GridItem>
          <GridItem rowSpan={2} colSpan={1} >
            <StatTile
              title="Total Active Students"
              value={data.totalStudents}
            />
          </GridItem>
          <GridItem rowSpan={2} colSpan={1} >
            <StatTile
              title="Tests Last Month"
              value={data.testsLastMonth} />
          </GridItem>
          <GridItem rowSpan={2} colSpan={1} >
            <StatTile title="Average Score Percentage" units="%" value={data.avgScore} format="percentage" />
          </GridItem>
        </Grid>
        :
        <Loading />
      }
    </>
  )
}

export default Dashboard;

