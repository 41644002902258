import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Center, HStack, Spacer, Text, Flex, Table, TableContainer, Tbody, Thead, Th, Tr, Td, IconButton } from "@chakra-ui/react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { AccountContext } from "../Account";
import React from 'react'
import { Loading } from "../loading";
import { SearchBar } from "../search";
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK } from "../../theme";

const R = require('ramda');

export class Column {
  id: string;
  name: string;
  format: string;
  component: Function;
  onClick: Function;

  constructor(id: string, name: string, format: string, component: Function, onClick: Function) {
    this.id = id
    this.name = name
    this.format = format
    this.component = component
    this.onClick = onClick
  }
}

interface Props {
  columns: object
  fetchItems: Function
  fetchItemsWithSearch?: Function
  icon?: ReactNode
  render?: boolean
  searchable?: boolean
  searchOnChange?: boolean
  hidePagination?: boolean
  children: ReactNode | ReactNode[]
  itemTestId?: Function
}

export const AnalyticaTable: React.FC<Props> = ({ searchable = false, searchOnChange = false, hidePagination = false, ...props }) => {

  const { token } = useContext(AccountContext)

  const [page, setPage] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [items, setItems] = useState<any | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  useEffect(() => {
    if (token) {
      loadItems()
    }
  }, [token, search, page, limit, props.render]);

  const start = () => {
    return (page - 1) * limit
  }

  const loadItems = () => {
    if (!props.fetchItems && !props.fetchItemsWithSearch) {
      return
    }
    if (search) {
      if (!props.fetchItemsWithSearch) {
        console.error("No 'fetchItemsWithSearch' function has been provided")
        return
      }
      props.fetchItemsWithSearch(token, search, setItems, start(), limit);
    } else {
      props.fetchItems(token, setItems, start(), limit);
    }
  }

  if (!items) {
    return (
      <Loading />
    )
  }

  let isEmpty = items && R.isEmpty(items)

  let searchBar = <SearchBar setSearch={setSearch} searchOnChange={searchOnChange} />

  let tableHeaderRow = R.map((column: Column) => <Th pb={2} px={5}>{column.name}</Th>, props.columns)

  const value = (column: Column, item: any) => {
    // Don't hate me, this function could be much better, I know! :)
    if (column.component) {
      let value
      if (column.onClick) {
        value = column.component(item, column.onClick(item, items, setItems))
      } else {
        value = column.component(item, items, setItems)
      }
      if (value === 0) {
        return 0
      }
      if (!value) {
        return "-"
      }
      return value
    }
    let value = item[column.id]
    if (value) {
      if (isNaN(value)) {
        return value
      }
      if (Number.isInteger(value)) {
        return value
      }
      // else must be decimal
      if (column.format === "percentage") {
        return `${Number(value * 100).toFixed(1)}%`
      } else {
        return Number(value).toFixed(2)
      }
    }
    if (value === 0) {
      return 0
    }
    return '-'
  }

  let tableRows = R.map((item: any) => {
    let testId = props.itemTestId ? props.itemTestId(item) : item.id
    return <Tr
      key={testId}
      test-id={testId}
      h={50}
      borderTop={BORDER_DEFAULT}
    >
      {props.icon && [<Td px={0} py={1} pl={5}>{props.icon}</Td>]}
      {R.map((column: Column) => <Td
        p={0}
        px={5}
        test-id={column.id}
      >{value(column, item)}</Td>, props.columns)}
    </Tr>
  }, items)

  let table =
    <TableContainer test-id="items-list">
      <Table size="md" variant="unstyled">
        <Thead>
          <Tr>
            {props.icon && [<Th />]}
            {tableHeaderRow}
          </Tr>
        </Thead>
        <Tbody>
          {tableRows}
        </Tbody>
      </Table>
    </TableContainer>

  const pageLeft = () => {
    setPage(page - 1)
  }

  const pageRight = () => {
    setPage(page + 1)
  }

  let numberOfResults = <Text>{`Showing Results: ${start() + 1}-${start() + limit}`}</Text>

  let pagination =
    <Box>
      <Flex>
        <Spacer />
        <HStack>
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={pageLeft}
            isDisabled={page === 1}
            bg="transparent"
            color={COLOUR_PRIMARY_DARK}
            aria-label={""} />
          <Text>Page {page}</Text>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={pageRight}
            bg="transparent"
            color={COLOUR_PRIMARY_DARK}
            aria-label={""} />
        </HStack>
      </Flex>
    </Box>

  let noResults =
    <Center>
      <Text m={150}>No Results Found...</Text>
    </Center>

  return (
    <>
      <Box borderRadius="2xl" w="full" h="full" >
        <HStack>
          {searchable && searchBar}
          <Spacer />
          {props.children && props.children}
        </HStack>
      </Box>
      <Box pt={3} />
      <Box
        borderRadius="2xl"
        bg="white"
        w="full"
        h="full"
        border={BORDER_DEFAULT}
      >
        {!hidePagination &&
          <HStack px={5} py={2}>
            {numberOfResults}
            <Spacer />
            {pagination}
          </HStack>
        }

        <Box pt={5} />
        {isEmpty ? noResults : table}
      </Box>
    </>
  )
}
