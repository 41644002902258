import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAdminByCognitoId, fetchStaffByCognitoId, updateAdmin, updateStaff } from "../../data/request";
import { AccountContext } from "../Account";
import { validateName } from "../../data/validations";
import React from "react";
import { AnalyticaForm } from "../form";
import { Loading } from "../loading";
import { TextInput } from "../form/input";
import { Avatar, Box, Heading, HStack, IconButton, Spacer, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { AnalyticaModal } from "../modal";
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, ICON_EDIT, RADIUS_PRIMARY } from "../../theme";
import { TableIconButton } from "../button";
import { Admin, StaffMember, User } from "../../data/classes";

export const Profile = () => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  const { token, updateDetails, user, isStaff, isAdmin } = useContext(AccountContext)
  const userIsStaff = isStaff()
  const userIsAdmin = isAdmin()

  const [userData, setUserData] = useState<User | null>(null)

  useEffect(() => {
    if (token && user && !userData) {
      let fetch = userIsStaff ? fetchStaffByCognitoId : fetchAdminByCognitoId
      fetch(token, user.sub, setUserData)
    }
  }, [token, user, userData, userIsStaff]);

  const load = () => {
    if (token && user) {
      let fetch = userIsStaff ? fetchStaffByCognitoId : fetchAdminByCognitoId
      fetch(token, user.sub, setUserData)
    }
  }

  const closeAndRender = () => {
    onClose()
    load()
  }

  const onSuccess = () => {
    onClose()
  }

  const onError = (err: Error) => {
    console.error(err.message)
  }

  const updateUserProfile = (token: string, values: any, callback: Function) => {
    if (!token) {
      console.error("No Token Available")
      return
    }
    const updateInCognito = () => {
      // update in cognito
      updateDetails(values.preferredName, values.lastName, onSuccess, onError)
      callback()
    }
    // update in db
    if (userIsStaff) {
      updateStaff(token, values as StaffMember, updateInCognito)
    } else if (userIsAdmin) {
      updateAdmin(token, values as Admin, updateInCognito)
    }
  }

  const userTitle = () => {
    if (!user) {
      return "?"
    }
    if (user && !user.given_name && !user.family_name) {
      return user.email
    }
    return `${user.given_name} ${user.family_name}`
  }

  const fullname = () => {
    if (!user) {
      return "-"
    }
    let fn = ''
    fn += user.given_name || '-'
    fn += ' '
    fn += user.family_name || '-'
    return fn
  }

  const email = () => {
    if (!user || !user.email) {
      return "-"
    }
    return user.email
  }

  let title = userTitle()
  let userFullName = fullname()
  let userEmail = email()

  return (
    <>
      <Box
        padding={10}
        background={COLOUR_PRIMARY_WHITE}
        borderRadius={RADIUS_PRIMARY}
        border={BORDER_DEFAULT}
        w="full"
      >

        <HStack>
          <Avatar
            name={title}
            src={""}
            size="xl"
          />
          <Box w={10} />
          <VStack>
            <Text fontSize="lg">{userFullName}</Text>
            <Text>{userEmail}</Text>
          </VStack>
          <Spacer />
          <TableIconButton
            icon={ICON_EDIT}
            testId="edit-profile-button"
            onClick={onOpen}
          />
          {/* <IconButton */}
          {/*   icon={ICON_EDIT} */}
          {/*   test-id="edit-profile" */}
          {/*   aria-label="" */}
          {/*   onClick={() => { }} */}
          {/* /> */}
        </HStack>
      </Box>

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={closeAndRender}
      >
        <AnalyticaForm
          name="update-profile"
          title="Update Profile"
          description='Update your User Profile.'
          initialValues={userData || {}}
          // onSubmit={item ? updateOrg : addOrg}
          onSubmit={updateUserProfile}
          onClose={closeAndRender}
        >
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId="user-preferred-name-input"
            validation={validateName}
          />
          <Box h={5} />
          <TextInput
            name="lastName"
            title="Last Name"
            testId="user-last-name-input"
            validation={validateName}
          />
        </AnalyticaForm>
      </AnalyticaModal>
    </>
  )
}
