import { Search2Icon } from "@chakra-ui/icons";
import { Box, Button, Input, InputGroup, InputLeftElement, InputRightAddon } from "@chakra-ui/react";
import { useState } from "react";
import React from "react";

interface Props {
  setSearch: Function
  searchOnChange: boolean
}

export const SearchBar: React.FC<Props> = ({
  setSearch,
  searchOnChange = false
}) => {

  const [searchText, setSearchText] = useState("")

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (searchOnChange) {
      setSearch(e.currentTarget.value)
    } else {
      setSearchText(e.currentTarget.value)
    }
  }

  const onClick = () => {
    setSearch(searchText)
  }

  return (
    <>
      <InputGroup
        size="lg"
        width={400}
      >

        <InputLeftElement
          pointerEvents="none"
          children={<Search2Icon color="#D5D5D5" />}
        />
        <Input
          borderRadius={10}
          type="text"
          placeholder="Search..."
          background="white"
          border="2px solid #D5D5D5"
          test-id="search-text"
          onChange={onChange}
        />
        {!searchOnChange &&
          <InputRightAddon
            p={0}
            border="none"
          >
            <Button
              background="#D5D5D5"
              opacity="60%"
              textColor="white"
              borderLeftRadius={0}
              borderRightRadius={3.3}
              border="1px solid #949494"
              onClick={onClick}
            >
              Search
            </Button>
          </InputRightAddon>
        }
      </InputGroup>
    </>
  );
}
