import { useFormikContext } from "formik";
import React from "react"
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_GREY, COLOUR_PRIMARY_LIGHT } from "../../theme";
import { ModalButton, AnalyticaButtonProps } from "./AnalyticaButton"

export interface DeleteButtonProps extends AnalyticaButtonProps { }

export const DeleteButton = (props: DeleteButtonProps) => {

  const { submitForm } = useFormikContext();

  return (
    <ModalButton
      background={COLOUR_PRIMARY_LIGHT}
      textColor="black"
      border={BORDER_DEFAULT}
      onClick={submitForm}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      {...props}
    >Delete</ModalButton>
  )
}

