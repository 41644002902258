import { List, ListItem, Heading, Text, HStack, Box, Divider } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom';
import { AccountContext } from '../Account';
import { useContext, useEffect, useState } from 'react';
import { fetchOrgById, fetchStaffByCognitoId } from '../../data/request';
import React from 'react';
import { Loading } from '../loading';
import { Org, StaffMember } from '../../data/classes';
import { ICON_ADMIN, ICON_CLASS, ICON_DASHBOARD, ICON_ORG, ICON_STAFF } from '../../theme';

export const Sidebar = () => {

  const { token, user, isAdmin, isStaff } = useContext(AccountContext)

  const [org, setOrg] = useState<Org | null>(null)
  const [staffMember, setStaffMember] = useState<StaffMember | null>(null)

  const userIsAdmin = isAdmin()
  const userIsStaff = isStaff()

  useEffect(() => {
    if (token) {
      if (userIsStaff) {
        if (staffMember) {
          fetchOrgById(token, staffMember.orgId, setOrg)
        } else if (user) {
          fetchStaffByCognitoId(token, user.sub, setStaffMember)
        }
      }
    }
  }, [token, staffMember]);

  if (userIsStaff && !org) {
    return <Loading />
  }

  return (
    <Box m={6} >

      <Heading size="lg">Rubric Analytica</Heading>
      <Divider mt={8} borderWidth="1px" borderColor="#D5D5D5" />
      <List pt={8} spacing={5}>

        <ListItem>
          <NavLink to="/home" test-id="dash-nav">
            <HStack>
              {ICON_DASHBOARD}
              <Text pl={3} fontSize="lg">Dashboard</Text>
            </HStack>
          </NavLink>
        </ListItem>

        {userIsAdmin &&
          <>
            <ListItem>
              <NavLink to="/orgs" test-id="org-nav">
                <HStack>
                  {ICON_ORG}
                  <Text pl={3} fontSize="lg">Organizations</Text>
                </HStack>
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink to="/admins" test-id="admin-nav">
                <HStack>
                  {ICON_ADMIN}
                  <Text pl={3} fontSize="lg">Admins</Text>
                </HStack>
              </NavLink>
            </ListItem>
          </>
        }

        {userIsStaff &&
          <>
            <ListItem>
              <NavLink to={`/orgs/${org ? org.id : ""}/classes`} test-id="class-nav">
                <HStack>
                  {ICON_CLASS}
                  <Text pl={3} fontSize="lg">Classes</Text>
                </HStack>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to={`/orgs/${org ? org.id : ""}/staff`} test-id="staff-nav">
                <HStack>
                  {ICON_STAFF}
                  <Text pl={3} fontSize="lg">Staff</Text>
                </HStack>
              </NavLink>
            </ListItem>
          </>
        }
      </List>
    </Box>
  )
}
