import { FormLabel, HStack } from "@chakra-ui/react"
import React from "react"
import { COLOUR_SECONDARY_DARK } from "../../../theme"

export interface LabelProps {
  title: string
  optional: boolean
}

export const Label = (props: LabelProps) => {
  return (
    <HStack >
      <FormLabel
        pt={3}
        fontSize="sm"
      >{props.title}</FormLabel>
      {props.optional &&
        <FormLabel
          pt={3}
          fontSize="sm" textColor={COLOUR_SECONDARY_DARK}
        >(Optional)</FormLabel>
      }
    </HStack>
  )
}

