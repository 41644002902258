import React from 'react'
import { TextInput, TextInputProps } from './TextInput'
import { validatePassword } from '../../../data/validations'

export interface PasswordInputProps extends Omit<TextInputProps, 'name' | 'title' | 'validation'> {
  name?: string
  title?: string
  validation?: (arg0: string) => string | undefined
}

export const PasswordInput = (props: PasswordInputProps) => (
  <TextInput
    type="password"
    name={props.name || "password"}
    title={props.title || "Password"}
    validation={props.validation || validatePassword}
    {...props}
  />
)
