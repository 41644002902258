import { Box, Button, HStack, Heading, Text, Spacer } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React from "react";

export const Settings = () => {

  const navigate = useNavigate()

  const save = () => {
    navigate("/home")
  }

  return (
    <>
      <Box borderRadius="2xl" p={8} bg="white" w="full" h="full">
        <Heading size="md">Settings</Heading>

        <Spacer h="50px" />

        <Text size="md">Settings Coming soon...</Text>
        <Spacer h="50px" />

        <HStack pt={10}>
          <Button onClick={save} test-id="save-class-button">Save</Button>
        </HStack>
      </Box>
    </>
  )
}
