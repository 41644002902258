import { useFormikContext } from "formik";
import React from "react"
import { BORDER_PRIMARY_DARK, COLOUR_PRIMARY_WHITE, COLOUR_SECONDARY_DARK } from "../../theme";
import { ModalButton, AnalyticaButtonProps } from "./AnalyticaButton"

interface Props extends AnalyticaButtonProps { }

export const ResetPasswordButton = (props: Props) => {

  const { submitForm } = useFormikContext();

  return (
    <ModalButton
      background={COLOUR_SECONDARY_DARK}
      textColor={COLOUR_PRIMARY_WHITE}
      border={BORDER_PRIMARY_DARK}
      onClick={submitForm}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      {...props}
    >Reset Password</ModalButton>
  )
}

