import { Box, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Spacer, Text } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import React, { ReactNode, useContext } from "react";
import { BORDER_DEFAULT } from "../../theme";
import { AccountContext } from "../Account";
import { CancelButton, SaveButton } from "../button";

interface Props {
  name: string
  title: string
  description: string
  initialValues: object
  children: ReactNode | ReactNode[]
  onSubmit: (...args: any[]) => void
  onClose: () => void
}

export const AnalyticaForm = (props: Props) => {

  const { token } = useContext(AccountContext)

  return (
    <Formik
      initialValues={props.initialValues || {}}
      validateOnMount
      onSubmit={(values, actions) => {
        setTimeout(() => {
          props.onSubmit(token, values, () => {
            actions.setSubmitting(false)
            actions.resetForm()
            props.onClose()
          })
        }, 1000)
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <ModalContent borderRadius={10} border={BORDER_DEFAULT}>

            <ModalHeader>{props.title}</ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Text>{props.description}</Text>
              <Box pt={10} />
              {props.children}
              <Box pt={10} />
            </ModalBody>

            <ModalFooter>
              <CancelButton w="25%" />
              <Spacer />
              <SaveButton
                w="25%"
                testId="save-button"
                isLoading={isSubmitting}
                isDisabled={!isValid} />
            </ModalFooter>

          </ModalContent>
        </Form>
      )}
    </Formik>
  )
}

