import { useFormikContext } from "formik";
import React from "react"
import { ModalButton, AnalyticaButtonProps } from "./AnalyticaButton"

interface Props extends AnalyticaButtonProps { }

export const SaveButton = (props: Props) => {

  const { submitForm } = useFormikContext();

  return (
    <ModalButton
      background="#666276"
      textColor="white"
      border="2px solid #403D58"
      onClick={submitForm}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      {...props}
    >Save</ModalButton>
  )
}

