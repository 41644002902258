import { Box, Center, Heading, Text } from "@chakra-ui/react"
import React from 'react'

export const PageNotFound = () => {

  return (
    <>
      <Box w="full" h="full" test-id="not-found-page">
          <Box w={400} position='absolute' left='50%' top='50%' transform='translate(-50%, 50%)'>
            <Center>
              <Heading opacity="80%" size="lg">Page Not Found</Heading>
            </Center>

            <Box h={10}/>

            <Center>
              <Text align="center" opacity="80%" size="sm">Sorry, we could not find the page you were looking for!</Text>
            </Center>

          </Box>
      </Box>
    </>
  )
} 
