import React from 'react'
import { Center, Spinner } from '@chakra-ui/react'

export const Loading: React.FC = () => {
  return (
    <Center pt={10}>
      <Spinner />
    </Center>
  )
}

