import { Button } from "@chakra-ui/react"
import { BORDER_DEFAULT } from "../../theme"
import React, { MouseEventHandler } from "react"

export interface AnalyticaButtonProps {
  onClick?: MouseEventHandler<HTMLElement>
  background?: string
  textColor?: string
  border?: string
  isDisabled?: boolean
  children?: string
  isLoading?: boolean
  w?: string
  testId?: string
}

export const ModalButton = (props: AnalyticaButtonProps) => {

  return (
    <Button
      onClick={props.onClick}
      background={props.background}
      textColor={props.textColor}
      border={props.border ? props.border : BORDER_DEFAULT}
      borderRadius={10}
      isDisabled={props.isDisabled}
      isLoading={props.isLoading}
      w={props.w || "full"}
      test-id={props.testId}
      padding={5}
    >
      {props.children}
    </Button>
  )
}
