import { Box, Button } from "@chakra-ui/react";
import React, { MouseEventHandler, ReactElement } from "react";
import { AnalyticaButtonProps } from "./AnalyticaButton";

interface Props extends AnalyticaButtonProps {
  title: string
  rightIcon?: ReactElement
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const AddButton = (props: Props) => {
  return (
    <Button
      rightIcon={props.rightIcon}
      onClick={props.onClick}
      bg="#666276"
      textColor="white"
      borderRadius={10}
      border="2px solid #403D58"
      p={6}
      test-id={props.testId}
    >
      {props.title}
      <Box w={2} />
    </Button>
  )
}
