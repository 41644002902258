import { Tag, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import React from 'react'
import { Class, Org, Test } from '../../../data/classes';
import { Loading } from '../../loading/loading';
import { deleteTest, downloadTestPDF, fetchClassById, fetchOrgById, fetchTestsByClassId, generateTest, updateTest } from '../../../data/request';
import { AccountContext } from '../../Account';
import { AddButton } from '../../button/AddButton';
import { AnalyticaTable } from '../../table/analytica-table';
import { BORDER_DEFAULT, ICON_DELETE, ICON_DOWNLOAD, ICON_EDIT, ICON_RESULT, ICON_TEST } from '../../../theme';
import { TableIconButton } from '../../button';
import { AnalyticaModal } from '../../modal';
import { AnalyticaForm, DeleteForm } from '../../form';
import { TextInput } from '../../form/input';
import { validateNonEmpty } from '../../../data/validations';
import { DateTimeInput } from '../../form/input/DateTimeInput';

export const Tests = () => {

  const navigate = useNavigate()
  const [render, setRender] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  let { orgId, classId } = useParams()
  const [org, setOrg] = useState<Org | null>(null)
  const [clazz, setClass] = useState<Class | null>(null)

  const [item, setItem] = useState<Test | null>(null)

  const { token, setPageTitle } = useContext(AccountContext)
  setPageTitle("Tests")

  useEffect(() => {
    if (token) {
      if (orgId) {
        fetchOrgById(token, orgId, setOrg)
      }
      if (classId) {
        fetchClassById(token, classId, setClass)
      }
    }
  }, [token, orgId, classId, render]);

  if (!org || !clazz) {
    return <Loading />
  }

  const closeAndRender = (close: () => void) => {
    close()
    setRender(!render)
  }

  const fetchTests = (token: string, setItems: Function, start: number, limit: number) => {
    if (!classId) {
      return
    }
    fetchTestsByClassId(token, classId, setItems, start, limit);
  }

  const rerender = () => {
    // force a rerender
    setRender(!render)
  }

  const generateTestForClass = (classId: string) => {
    if (!token) {
      return
    }
    try {
      generateTest(token, classId, rerender)
    } catch (error) {
    }
  }

  const download = (test: Test) => {
    if (!token) {
      return
    }
    let d = dayjs(new Date(test.created))
    downloadTestPDF(token, test.id, `${clazz.name}_${d.format("DDMMYY")}.pdf`)
  }

  const createdString = (t: Test) => {
    let date = new Date(t.created)
    const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
    return date.toDateString() + " - " + formatter.format(date);
  }

  const edit = (t: Test) => {
    setItem(t)
    onOpen()
  }

  const deleteItem = (t: Test) => {
    setItem(t)
    onOpenDelete()
  }

  const testStatus = (t: Test) =>
    <Tag
      border={BORDER_DEFAULT}
      bg={t.completed ? "#F0FFF4" : "#FFF5EE"}
    >{t.completed ? "COMPLETED" : "PENDING"}</Tag>

  const downloadFile = (t: Test) =>
    <TableIconButton icon={ICON_DOWNLOAD}
      testId="download-button"
      onClick={() => download(t)}
    />

  const viewResults = (t: Test) =>
    <TableIconButton icon={ICON_RESULT}
      testId="view-test-results"
      onClick={() => navigate("./" + t.id + "/results")}
    />

  const editTestButton = (t: Test) =>
    <TableIconButton
      icon={ICON_EDIT}
      testId="edit-test"
      onClick={() => edit(t)}
    />

  const deleteTestButton = (t: Test) =>
    <TableIconButton
      icon={ICON_DELETE}
      testId="delete-test"
      isDisabled={t.completed}
      onClick={() => deleteItem(t)}
    />

  let columns = [
    { name: "Created", component: createdString },
    { name: "Status", "component": testStatus },
    // { id: "numStudentsCompleted", name: "#Students Completed" },
    { id: "avgScore", name: "Avg. Score", format: "percentage" },
    { id: "avgTimeRemaining", name: "Avg. Time Remaining (s)" },
    { name: "Download", component: downloadFile },
    { name: "View Results", component: viewResults },
    { name: "Edit", component: editTestButton },
    { name: "Delete", component: deleteTestButton },
  ]

  return (
    <>
      <AnalyticaTable
        columns={columns}
        icon={ICON_TEST}
        fetchItems={fetchTests}
        render={render}
      >
        <AddButton
          title="Generate Test"
          rightIcon={<AddIcon />}
          onClick={() => generateTestForClass(clazz.id)}
          testId="generate-test-button"
        />
      </AnalyticaTable>

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => closeAndRender(onClose)}
      >
        <AnalyticaForm
          initialValues={item || {}}
          onSubmit={updateTest}
          onClose={() => closeAndRender(onClose)}
          name='update-test'
          title='Update Test Details'
          description={`Update the test date and time.`}
        >
          <DateTimeInput
            name="created"
            title="Created"
            testId='test-created-input'
            validation={validateNonEmpty}
          />
        </AnalyticaForm>
      </AnalyticaModal>

      <AnalyticaModal
        isOpen={isOpenDelete}
        onOpen={onOpenDelete}
        onClose={() => closeAndRender(onCloseDelete)}
      >
        <DeleteForm
          name='class-delete-modal'
          description={`Are you sure you want to delete Test - ${item?.created}?`}
          initialValues={item || {}}
          onSubmit={deleteTest}
          onClose={() => closeAndRender(onCloseDelete)}
        />
      </AnalyticaModal>
    </>
  )
}

