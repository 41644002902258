import { IconButton, NumberInput, NumberInputField, Tag, Th, Tr } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck, FaCheckCircle, FaFlagCheckered, FaRegEdit } from "react-icons/fa";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { fetchStudentsByClassId, fetchTestById, completeTest, fetchResultsByTestId, fetchClassById, fetchOrgById, upsertResult } from "../../../data/request";
import { AccountContext } from "../../Account";
import { Class, Org, Result, Student, StudentAndResult, Test } from "../../../data/classes";
import { Loading } from "../../loading";
import { AnalyticaTable } from "../../table";
import { RiCloseFill } from "react-icons/ri";
import { BORDER_DEFAULT, COLOUR_PRIMARY_DARK, COLOUR_PRIMARY_GREY, COLOUR_PRIMARY_LIGHT, COLOUR_PRIMARY_WHITE, COLOUR_SECONDARY_LIGHT } from "../../../theme";
import { AddButton } from "../../button";

const R = require('ramda');

export const Results = () => {

  let { orgId, classId, testId } = useParams()
  const [render, setRender] = useState<boolean>(false)

  const [org, setOrg] = useState<Org | null>(null)
  const [clazz, setClass] = useState<Class | null>(null)
  const [test, setTest] = useState<Test | null>()

  const { token, setPageTitle } = useContext(AccountContext)
  setPageTitle("Results")

  const rerender = () => {
    setRender(!render)
  }

  useEffect(() => {
    if (token) {
      if (orgId) {
        fetchOrgById(token, orgId, setOrg)
      }
      if (classId) {
        fetchClassById(token, classId, setClass)
      }
      if (testId) {
        fetchTestById(token, testId, setTest)
      }
    }
  }, [token, orgId, classId, testId]);

  const fillResultData = (setItems: Function) => (students: Student[]) => (results: Result[]) => {
    let items = R.map(
      (s: Student) => {
        let r: Result = R.find(R.propEq(s.id, 'studentId'))(results)
        return new StudentAndResult(s, r)
      },
      students
    )
    setItems(items)
  }

  const fillStudentData = useCallback((setItems: Function) => (students: Student[]) => {
    if (!token || !testId) {
      return
    }
    fetchResultsByTestId(token, testId, fillResultData(setItems)(students), 0, Number.MAX_SAFE_INTEGER)
  }, [token, testId])

  const fetchAndTransformResults = useCallback((token: string, setItems: Function) => {
    if (!classId) {
      return
    }
    fetchStudentsByClassId(token, classId, fillStudentData(setItems), 0, Number.MAX_SAFE_INTEGER)
  }, [classId])

  const setTestAndRerender = (t: Test) => {
    setTest(t)
    rerender()
  }

  if (!org || !clazz || !test) {
    return <Loading />
  }

  const submitResultUpdate = (s: StudentAndResult) => {
    if (!token) {
      return
    }
    upsertResult(token, s.result, s.result.testId, s.result.studentId)
  }

  const complete = () => {
    if (!token) {
      return
    }
    if (!testId) {
      return
    }
    let tId = testId
    completeTest(token, test, () => fetchTestById(token, tId, setTestAndRerender))
  }

  const updateStudentResult = (item: StudentAndResult, items: StudentAndResult[], setItems: Function) => {
    if (!token) {
      console.error("No Token Available")
      return
    }
    let updated = R.map(
      (i: StudentAndResult) => {
        if (!i.result) {
          return i
        }
        if (i.result.studentId === item.result.studentId) {
          i = item
        }
        return i
      }, items)
    upsertResult(token, item.result, test.id, item.student.id)
    setItems(updated)
  }

  const enable = (item: StudentAndResult, items: StudentAndResult[], setItems: Function) => () => {
    if (!item.result) {
      item.result = new Result("", test.id, item.student.id, item.student.classId, org.id, 0.0, 0.0, false)
    }
    item.result.completed = !item.result.completed
    if (!item.result.completed) {
      item.result.score = 0
      item.result.timeRemaining = 0
    }
    updateStudentResult(item, items, setItems)
  }

  const icon = (item: StudentAndResult, onClick: React.MouseEventHandler<HTMLElement>) => {
    // red cross icon
    let bg = COLOUR_PRIMARY_LIGHT
    let icon = <RiCloseFill size={35} />
    // green tick icon
    if (item.result && item.result.completed) {
      icon = <FaCheck size={20} />
      bg = COLOUR_SECONDARY_LIGHT
    }
    return <IconButton
      w={50}
      icon={icon}
      color={COLOUR_PRIMARY_GREY}
      bg={bg}
      border={BORDER_DEFAULT}
      onClick={onClick}
      isDisabled={test.completed}
      _disabled={{
        bg: bg,
        _hover: {
          bg: bg
        }
      }}
      aria-label=""
    />
  }

  const scoreInput = (item: StudentAndResult, items: StudentAndResult[], setItems: Function) => {
    return <NumberInput
      id='score'
      value={item.result && item.result.score ? item.result.score * 25 : undefined}
      onChange={(e: any) => {
        item.result.score = isNaN(e) ? 0.0 : e / 25
        updateStudentResult(item, items, setItems)
      }}
      isDisabled={item.result ? !item.result.completed : true}
      min={0}
      max={25}
    >
      <NumberInputField
        border={BORDER_DEFAULT}
        test-id='score-input'
      />
    </NumberInput>
  }

  const timeRemainingInput = (item: StudentAndResult, items: StudentAndResult[], setItems: Function) => {
    return <NumberInput
      id='timeRemaining'
      value={item.result && item.result.timeRemaining ? item.result.timeRemaining : undefined}
      onChange={(e: any) => {
        item.result.timeRemaining = isNaN(e) ? 0.0 : parseFloat(e)
        updateStudentResult(item, items, setItems)
      }}
      isDisabled={item.result ? !item.result.completed : true}
      min={0}
    >
      <NumberInputField
        border={BORDER_DEFAULT}
      />
    </NumberInput>
  }

  const scoreComplete = (item: StudentAndResult) => {
    return item && item.result && item.result.score ? item.result.score * 25 : '-'
  }

  const identifier = (item: StudentAndResult) => {
    return item.student.identifier
  }

  const fullname = (item: StudentAndResult) => {
    return `${item.student.preferredName} ${item.student.lastName}`
  }

  const timeRemaining = (item: StudentAndResult) => {
    return item && item.result ? item.result.timeRemaining : '-'
  }

  const itemTestId = (item: StudentAndResult) => {
    return item.student.id
  }

  let columns
  if (test.completed) {
    columns = [
      { id: "identifier", name: "Identifier", component: identifier },
      { id: "name", name: "Name", component: fullname },
      { id: "completed", name: "Completed", component: icon },
      { id: "score", name: "Score", component: scoreComplete },
      { id: "timeRemaining", name: "Time Remaining", component: timeRemaining },
    ]
  } else {
    columns = [
      { id: "identifier", name: "Identifier", component: identifier },
      { id: "name", name: "Name", component: fullname },
      { id: "completed", name: "Completed", component: icon, onClick: enable },
      { id: "score", name: "Score", component: scoreInput },
      { id: "timeRemaining", name: "Time Remaining", component: timeRemainingInput },
    ]
  }

  let completeButton =
    <AddButton
      rightIcon={<FaFlagCheckered />}
      onClick={complete}
      title="Finish Test"
      testId="finish-test-button"
    />

  return (
    <AnalyticaTable
      columns={columns}
      fetchItems={fetchAndTransformResults}
      hidePagination
      render={render}
      itemTestId={itemTestId}
    >
      {!test.completed && completeButton}
    </AnalyticaTable>
  )
}
