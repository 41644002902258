import { HStack, Input } from "@chakra-ui/react"
import { Field, FieldProps, useFormikContext } from "formik"
import React, { ChangeEvent, ChangeEventHandler, useState } from 'react'
import { BORDER_DEFAULT, COLOUR_PRIMARY_WHITE, RADIUS_PRIMARY } from "../../../theme"
import { Control } from "../control"
import { Error } from "../error"
import { validateDate } from "../../../data/validations"
import { Label } from "./Label"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css"
import { RAInputProps } from "./RAInputProps"

export interface DateTimeInputProps extends RAInputProps {
}

export const DateTimeInput = (props: DateTimeInputProps) => {

  const { setFieldValue } = useFormikContext()

  return (
    <Field
      name={props.name || 'datetime-input-field'}
      validate={props.validation || validateDate}>
      {({ field, form }: FieldProps) => (
        <Control form={form} name={props.name || 'text-input'} >

          {props.title && <Label optional={props.optional || false} title={props.title} />}

          <DatePicker
            wrapperClassName="datePicker"
            selected={new Date(field.value)}
            onChange={(date) => setFieldValue(field.name, date)}
            dateFormat="Pp"
            showTimeSelect
          />

          {props.name && <Error form={form} name={props.name} />}

        </Control>
      )}
    </Field>
  )
}
