import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./Account"
import React from 'react'
import { AuthForm } from "./form";
import { ConfirmPasswordInput, PasswordInput } from "./form/input";
import { ResetPasswordButton } from "./button";
import { Box } from "@chakra-ui/react";

export const ResetPassword = () => {

  const navigate = useNavigate()
  const [error, setError] = useState<string | null>(null)
  const { resetPassword } = useContext(AccountContext)

  const onSuccess = () => {
    console.log("SUCCESS")
    navigate("/login", { replace: true, state: { msg: "Password Reset Success! You may now Login." } });
  }

  const onError = (err: Error) => {
    // This is placeholder text for now, don't worry about it. Look the other way please. There is logic in my head about it.
    console.log(err)
    setError(err.name === "InvalidParameterException" ? "Error Resetting Password" : "Error Resetting Password")
  }

  const onSubmit = (values: any) => {
    resetPassword(values.password, onSuccess, onError)
  }

  return (
    <AuthForm
      name="resetPassword"
      title="Reset Password"
      buttonText="Reset Password"
      error={error}
      onSubmit={onSubmit}
    >
      <PasswordInput
        title="New Password"
        testId="new-password-input"
        w="full" />
      <ConfirmPasswordInput
        title="Confirm New Password"
        testId="confirm-new-password-input"
        w="full" />
      <Box pt={4} />
      <ResetPasswordButton testId="reset-password-button" />
    </AuthForm>
  )
}
