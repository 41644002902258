import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { addStudent, deleteStudent, fetchClassById, fetchOrgById, fetchStudentsByClassId, fetchStudentsByClassIdWithSearch, updateStudent } from '../../../data/request';
import React from 'react';
import { AccountContext } from '../../Account';
import { Loading } from '../../loading';
import { AnalyticaTable } from '../../table';
import { AnalyticaForm, DeleteForm } from '../../form';
import { Class, Org, Student } from '../../../data/classes';
import { Box, useDisclosure } from '@chakra-ui/react';
import { validateName, validateNonEmpty } from '../../../data/validations';
import { ICON_ADD, ICON_DELETE, ICON_EDIT, ICON_IMPORT, ICON_STUDENT } from '../../../theme';
import { AnalyticaModal } from '../../modal';
import { ImportStudents } from './import-students/import-students';
import { IntegerInput, TextInput } from '../../form/input';
import { AddButton, TableIconButton } from '../../button';
import { fullname } from '../../../data/functions';

export const Students = () => {

  let { orgId, classId } = useParams();
  const [render, setRender] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenImport,
    onOpen: onOpenImport,
    onClose: onCloseImport
  } = useDisclosure()
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete
  } = useDisclosure()

  const [org, setOrg] = useState<Org | null>(null);
  const [clazz, setClass] = useState<Class | null>(null);

  const { token, setPageTitle } = useContext(AccountContext)
  setPageTitle("Students")

  const [students, setStudents] = useState<Student[]>([])
  const [item, setItem] = useState<Student | null>(null)

  useEffect(() => {
    if (token) {
      if (orgId) {
        fetchOrgById(token, orgId, setOrg)
      }
      if (classId) {
        fetchClassById(token, classId, setClass)
      }
    }
  }, [token, orgId, classId]);

  if (!orgId || !classId) {
    return <Loading />
  }

  if (!org || !clazz) {
    return <Loading />
  }

  if (!clazz) {
    return <Loading />
  }


  const closeAndRender = (close: () => void) => {
    close()
    setStudents([])
    setRender(!render)
  }

  const fetchStudentsWithSearch = (token: string, search: string, setItems: Function, start: number, limit: number) => {
    if (!classId) {
      return
    }
    fetchStudentsByClassIdWithSearch(token, classId, search, setItems, start, limit);
  }

  const fetchStudents = (token: string, setItems: Function, start: number, limit: number) => {
    if (!classId) {
      return
    }
    fetchStudentsByClassId(token, classId, setItems, start, limit);
  }

  const addNewStudent = (token: string, s: Student, callback: Function) => {
    if (!token) {
      return
    }
    if (!classId) {
      return
    }
    addStudent(token, s, classId, callback)
  }

  const saveImport = (tok: string, values: any, callback: Function) => {
    try {
      values.students.forEach((s: Student) => {
        addStudent(tok, { ...s, level: 1, orgId: org.id, classId: clazz.id }, clazz.id, () => { })
      })
    } catch (err) {
      return
    }
    callback()
  }

  const add = () => {
    setItem(null)
    onOpen()
  }

  const edit = (student: Student) => {
    setItem(student)
    onOpen()
  }

  const deleteItem = (student: Student) => {
    setItem(student)
    onOpenDelete()
  }

  const editStudentButton = (student: Student) =>
    <TableIconButton
      icon={ICON_EDIT}
      testId="edit-student"
      onClick={() => edit(student)}
    />

  const deleteStudentButton = (student: Student) =>
    <TableIconButton
      icon={ICON_DELETE}
      testId="delete-student"
      onClick={() => deleteItem(student)}
    />

  let columns = [
    { name: "Name", component: fullname },
    { id: "level", name: "Level" },
    { id: "yearLevel", name: "yearLevel" },
    { id: "avgScore", name: "Avg. Score", format: "percentage" },
    { id: "avgTimeRemaining", name: "Avg. Time Remaining (s)" },
    { name: "Edit", component: editStudentButton },
    { name: "Delete", component: deleteStudentButton },
  ]

  return (
    <>
      <AnalyticaTable
        columns={columns}
        icon={ICON_STUDENT}
        fetchItems={fetchStudents}
        fetchItemsWithSearch={fetchStudentsWithSearch}
        render={render}
        searchable
        searchOnChange
      >
        <AddButton
          title="Import Students"
          rightIcon={ICON_IMPORT}
          onClick={onOpenImport}
          testId="import-students-button"
        />
        <AddButton
          title="Add Student"
          rightIcon={ICON_ADD}
          onClick={add}
          testId="add-student-button"
        />
      </AnalyticaTable>

      <ImportStudents
        title='Import Students'
        description={`Import multiple new students to ${clazz.name} at ${org.name}.`}
        initialValues={{
          id: '',
          level: 1,
          orgId: orgId,
          classId: classId,
          preferredName: '',
          lastName: '',
          identifier: '',
          yearLevel: 7
        }}
        isOpen={isOpenImport}
        onOpen={onOpenImport}
        onClose={() => closeAndRender(onCloseImport)}
        onSubmit={saveImport}
        students={students}
        setStudents={setStudents}
      />

      <AnalyticaModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={() => closeAndRender(onClose)}
      >
        <AnalyticaForm
          initialValues={item || { level: 1, orgId: org.id }}
          onSubmit={item ? updateStudent : addNewStudent}
          onClose={() => closeAndRender(onClose)}
          name='student-form'
          title='Add a Student'
          description={`Add a new Student to ${clazz.name} at ${org.name}.`}
        >
          <TextInput
            name="preferredName"
            title="Preferred Name"
            testId='student-preferred-name-input'
            validation={validateName}
          />
          <TextInput
            name="lastName"
            title="Last Name"
            testId='student-last-name-input'
            validation={validateName}
          />
          <TextInput
            name="identifier"
            title="Identifier"
            testId='student-identifier-input'
            validation={validateName}
          />
          <IntegerInput
            name="yearLevel"
            title="Year Level"
            testId='student-year-level-input'
            min={1}
            max={12}
            validation={validateNonEmpty}
          />
          {item &&
            <>
              <IntegerInput
                name="level"
                title="Level"
                testId='student-level-input'
                min={1}
                max={12}
                validation={validateNonEmpty}
              />
            </>
          }
        </AnalyticaForm>
      </AnalyticaModal>

      <AnalyticaModal
        isOpen={isOpenDelete}
        onOpen={onOpenDelete}
        onClose={() => closeAndRender(onCloseDelete)}
      >
        <DeleteForm
          name='student-delete-modal'
          description={`Are you sure you want to delete Student - ${item?.preferredName} ${item?.lastName}?`}
          initialValues={item || {}}
          onSubmit={deleteStudent}
          onClose={() => closeAndRender(onCloseDelete)}
        />
      </AnalyticaModal>
    </>
  )
}

