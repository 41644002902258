import { IconButton } from "@chakra-ui/react";
import React, { MouseEventHandler, ReactElement } from "react";
import { COLOUR_PRIMARY_DARK } from "../../theme";
import { AnalyticaButtonProps } from "./AnalyticaButton";

interface Props extends AnalyticaButtonProps {
  testId: string
  icon?: ReactElement
  onClick: MouseEventHandler<HTMLButtonElement>
  isDisabled?: boolean
}

export const TableIconButton = (props: Props) => {
  return (
    <IconButton
      bg="transparent"
      color={COLOUR_PRIMARY_DARK}
      icon={props.icon}
      test-id={props.testId}
      onClick={props.onClick}
      isDisabled={props.isDisabled || false}
      aria-label={""}
    />
  )
}
